import React from "react"

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <svg
      width="124"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <title>Layer 1</title>
        <g stroke="null" id="svg_1">
          <rect
            stroke="null"
            y="0.132428"
            id="svg_2"
            height="32"
            width="124"
            x="0.075307"
            fill="#111216"
          />
          <path
            stroke="null"
            id="svg_3"
            fill="#FFFFFF"
            d="m9.713239,18.185877l0,-4.968398l-4.11531,0l0,-5.149002l4.11531,0l0,-5.01344l4.3041,0l0,5.01344l4.0776,0l0,-5.01344l4.304098,0l0,5.01344l4.190899,0l0,5.149002l-4.190899,0l0,4.968398l4.190899,0l0,5.148901l-4.190899,0l0,4.832897l-4.304098,0l0,-4.832897l-4.0776,0l0,4.832897l-4.3041,0l0,-4.832897l-4.11531,0l0,-5.148901l4.11531,0l0,0zm4.3041,0l0,-4.968398l4.0776,0l0,4.968398l-4.0776,0l0,0z"
          />
          <rect
            stroke="null"
            id="svg_4"
            height="16.373603"
            width="4"
            y="3.039402"
            x="34"
            fill="#FFFFFF"
          />
          <rect
            stroke="null"
            id="svg_5"
            height="4"
            width="4"
            y="24.190026"
            x="34"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  )
}
